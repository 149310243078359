import React from 'react';

export function SinkLogo() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="100px" height="100%" viewBox="0 0 200.000000 75.000000"
         preserveAspectRatio="xMidYMid meet">
        
        <g transform="translate(0.000000,75.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M156 685 c-81 -29 -123 -101 -96 -166 16 -39 77 -73 163 -93 80 -19
        142 -49 157 -75 28 -52 -3 -121 -66 -147 -69 -29 -190 -8 -246 43 -23 21 -45
        7 -24 -16 29 -32 110 -65 169 -69 144 -11 246 94 192 198 -19 38 -68 66 -146
        85 -92 23 -120 35 -152 63 -33 31 -35 69 -5 107 48 61 134 71 224 25 64 -32
        94 -26 45 9 -67 48 -145 61 -215 36z"/>
        <path d="M779 671 c-16 -16 -29 -39 -29 -51 0 -26 18 -62 36 -73 8 -4 14 -19
        14 -32 0 -23 -3 -25 -54 -25 -52 0 -55 1 -58 28 -2 16 -9 27 -18 27 -12 0 -15
        -15 -15 -75 0 -60 3 -75 15 -75 9 0 16 11 18 28 3 26 6 27 58 27 l54 0 0 -154
        0 -154 -37 10 c-51 14 -99 44 -123 78 -25 35 -25 39 0 48 18 7 16 12 -27 64
        -25 32 -50 55 -54 52 -15 -9 -10 -92 7 -132 28 -69 104 -130 182 -147 18 -4
        45 -17 61 -31 28 -23 29 -23 46 -4 9 10 45 28 80 40 74 25 141 78 166 134 19
        42 26 131 10 140 -4 3 -29 -20 -55 -52 -33 -41 -42 -58 -31 -60 8 -2 15 -10
        15 -18 0 -42 -53 -88 -126 -110 l-44 -13 0 154 0 155 54 0 c52 0 55 -1 58 -27
        2 -17 9 -28 18 -28 12 0 15 15 15 75 0 60 -3 75 -15 75 -9 0 -16 -11 -18 -27
        -3 -27 -6 -28 -58 -28 -48 0 -54 2 -54 21 0 12 11 34 25 51 33 39 32 73 -4
        109 -38 38 -74 38 -112 0z m86 -56 c0 -27 -3 -30 -30 -30 -21 0 -31 5 -33 18
        -6 33 6 49 35 45 24 -3 28 -8 28 -33z m-7 -269 c-3 -183 -4 -191 -23 -191 -19
        0 -20 8 -23 189 -2 193 1 212 35 199 11 -4 13 -43 11 -197z"/>
        <path d="M1690 426 c0 -230 2 -266 15 -266 11 0 15 13 15 53 0 48 3 56 38 90
        l38 37 72 -90 c40 -49 77 -90 82 -90 25 0 13 24 -56 110 l-74 93 76 79 c61 63
        75 81 65 91 -10 9 -37 -13 -127 -107 l-114 -119 -2 188 c-2 140 -6 189 -15
        193 -10 3 -13 -54 -13 -262z"/>
        <path d="M1230 345 c0 -145 3 -186 13 -183 8 3 13 43 17 136 5 128 6 131 34
        161 55 57 134 47 176 -22 17 -27 20 -51 20 -154 0 -103 2 -123 15 -123 13 0
        15 21 15 128 0 113 -3 131 -23 166 -43 75 -140 97 -207 47 l-29 -21 -3 22 c-2
        12 -9 23 -15 26 -10 3 -13 -38 -13 -183z"/>
        </g>
        </svg>   
    );
}