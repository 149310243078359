import { IRepository } from '../interfaces/IRepository';
import { ILog } from '../interfaces/ILog';
import { IHistory } from '../interfaces/IHistory';

export const repository: IRepository = {
    setTime: (time: number) => {
        localStorage.setItem('time', `${time}`)
    },
    getTime: () => {
        return parseInt(localStorage.getItem('time') || '0')
    },
    setCurrentTaskName: (currentTaskName: string) => {
        localStorage.setItem('currentTaskName', currentTaskName)
    },
    getCurrentTaskName: () => {
        return localStorage.getItem('currentTaskName') || ''
    },
    setHistory: (history: IHistory[]) => {
        localStorage.setItem('history', JSON.stringify(history))
    },
    getHistory: () => {
        // 後方互換性のために残す，ver 1.0.0 になったら消しても良さそう．
        const history = JSON.parse(localStorage.getItem('history') || '[]')
        if (history[0] !== void 0 && typeof (history[0]) === 'string') {
            return history.map((taskName: string) => { return { taskName: taskName, isPinned: false } })
        }
        return history
    },
    setLogs: (logs: ILog[]) => {
        localStorage.setItem('logs', JSON.stringify(logs))
    },
    getLogs: () => {
        return JSON.parse(localStorage.getItem('logs') || '[]')
    },
    setHistoryLimit: (limit: number) => {
        localStorage.setItem('limit', `${limit}`)
    },
    getHistoryLimit: () => {
        return parseInt(localStorage.getItem('limit') || '10')
    },
    setDiscordWebhookUrl: (url: string) => {
        localStorage.setItem('discordWebhookUrl', `${url}`)
    },
    getDiscordWebhookUrl: () => {
        return localStorage.getItem('discordWebhookUrl') || ''
    }
}