import React from 'react';
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
// import logo from './veltiosoft_logo.svg';

export function Footer() {
    const theme = useTheme();
    const footerCssProps: React.CSSProperties = {
        width: "100vw",
        minHeight: "24px",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        overflowY: "scroll",
    }
    const linkCssProps: React.CSSProperties = {
        marginRight: '8px',
        marginLeft: '8px',
        marginTop: 'auto',
        marginBottom: 'auto',
        whiteSpace: 'nowrap',
    }
    return (
        <Box style={footerCssProps} display="flex">
            {/* <Box><img src={logo} height="100%" /></Box> */}
            <Box style={linkCssProps}>ver0.6.4</Box>
            <Box style={linkCssProps}>©Veltiosoft 2021</Box>
            <Box style={linkCssProps}><Link to="/policy" className="fix">プライバシーポリシー</Link></Box>
        </Box>
    );
}
