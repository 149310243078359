import Box from '@material-ui/core/Box';
import React, { Dispatch, lazy, SetStateAction, Suspense, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { ILog } from './interfaces/ILog';

import { repository } from './repositories/localStrage';

import { Header } from './components/molecules/header/Header';
import { Footer } from './components/molecules/footer/Footer';

/* pages */
const Tracker = lazy(() => import('./pages/tracker/Tracker'));
const Policy = lazy(() => import('./pages/policy/Policy'));
const Settings = lazy(() => import('./pages/settings/Settings'));
const Logs = lazy(() => import('./pages/logs/Logs'));

function PageTemplate(props: { inner: () => JSX.Element }) {
    return <Box display="flex" flexDirection="column" justifyContent="space-between" height="100vh">
        <Header />
        <Box flexGrow="1" overflow="scroll">
            {props.inner()}
        </Box>
        <Footer />
    </Box>
}



export function Sink() {
    const [logs, setLogs]: [ILog[], Dispatch<SetStateAction<ILog[]>>] = useState(repository.getLogs)
    const [margin, setMargin]: [number, Dispatch<SetStateAction<number>>] = useState(4)
    const [historyLimit, setHistoryLimit]: [number, Dispatch<SetStateAction<number>>] = useState(repository.getHistoryLimit())
    const [discordWebhookUrl, setDiscordWebhookUrl]: [string, Dispatch<SetStateAction<string>>] = useState(repository.getDiscordWebhookUrl())
    return (
        <BrowserRouter>
            <Suspense fallback={<PageTemplate inner={() => <div>Loading...</div>} />}>
                <Switch>
                    <Route exact path="/tracker" render={() => <PageTemplate inner={() => <Tracker repo={repository} setLogs={setLogs} logs={logs} historyLimit={historyLimit} margin={margin} discordWebhookUrl={discordWebhookUrl} />} />} />
                    <Route exact path="/logs" render={() => <PageTemplate inner={() => <Logs logs={logs} />} />} />
                    <Route exact path="/policy" render={() => <PageTemplate inner={() => <Policy />} />} />
                    <Route exact path="/settings" render={() => <PageTemplate inner={() => <Settings setLogs={setLogs} repo={repository} historyLimit={historyLimit} setHistoryLimit={setHistoryLimit} setMargin={setMargin} margin={margin} setDiscordWebhookUrl={setDiscordWebhookUrl} discordWebhookUrl={discordWebhookUrl} />} />} />
                    <Route path="/" render={() => <PageTemplate inner={() => <Tracker repo={repository} setLogs={setLogs} logs={logs} historyLimit={historyLimit} margin={margin} discordWebhookUrl={discordWebhookUrl} />} />} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}
