import React from 'react';
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
import { SinkLogo } from '../../atoms/svg/SinkLogo';

export function Header() {
    const theme = useTheme();
    const links = ['tracker', 'logs', 'settings']
    const headerCssProps: React.CSSProperties = {
        width: "100vw",
        height: "32px",
        backgroundColor: theme.palette.primary.main,
    }
    const linkCssProps: React.CSSProperties = {
        margin: '8px'
    }
    return (
        <Box style={headerCssProps} display="flex">
            <SinkLogo />
            {links.map((key: string) =>
                <Box style={linkCssProps}><Link key={key} to={`/${key}`} className="fix" >{key}</Link></Box>
            )}
        </Box>
    );
}